import Image from "next/image";

const myLoader = async ({ src, width, quality }) => {
  return `/api/external/image?name=${src}&size=${width}x${width}&q=${quality || 75}`
}

const myWatermarkLoader = ({ src, width, quality }) => {
  return `/api/external/image?name=${src}&size=${width}x${width}&q=${quality || 75}&ujiplkj=8CmxdCD6L7d`
}

const mySmartLoader = ({ src, width, quality }) => {
  return `/api/external/image?name=${src}&size=${width}x${width}&q=${quality || 75}&smart=true`
}

const MyImage = (props) => {
  return (
    <Image
        src={props.src}
        alt={props.alt || 'Camping in India'}
        {...props}
    />
  )
}

export default MyImage