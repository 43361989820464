import React from 'react'
import Image from 'lib/image'
import dynamic from "next/dynamic";
import press from '../../content/press.json';
import { FiExternalLink } from 'react-icons/fi'
import { FaQuoteLeft } from 'react-icons/fa6';

const Slider = dynamic(() => import('components/utilities/slider'), {
  ssr: false
})

export const PressBlockDesktop = () => {
  return <div className='body-p-x'>
    <h3 className="text-4xl font-medium text-gray-600 pt-12 pb-8">
      HireACamp in the media
    </h3>
    <Slider>
      {press.map((p, k) => <div key={k} className="my-1 pr-4 h-56">
        <a title="Read Full Article" href={p.url} target="_blank">
          <div className="h-full w-full shadow px-3 bg-white rounded-xl py-3 flex flex-col justify-between">
            <div>
              <FaQuoteLeft className="text-primary text-xl" />
              <p className="mt-2 text-black ">
                {p.snippet}
              </p>
            </div>
            <div className="border-t-[0.5px] border-gray-400 flex items-center mt-2 pt-3 justify-between">
              <div className="w-28 h-12 relative">
                <Image src={`assets/icons/press/${p.logo}`} layout="fill" className="object-contain object-left" alt={p.logo} />
              </div>
              <div>
                <FiExternalLink />
              </div>
            </div>
          </div>
        </a>
      </div>)}
    </Slider>
  </div>
}

const PressBlock = () => {
  return <div>
    <div className="flex overflow-x-scroll px-4 hide-scroll-bar">
      <div className="flex flex-nowrap space-x-4">
        {press.map((p, k) => <div key={k} className="my-1 mr-2 w-52">
          <a title="Read Full Article" href={p.url} target="_blank">
            <div className="h-full w-full shadow px-3 bg-white rounded-xl py-3 flex flex-col justify-between">
              <div>
                <FaQuoteLeft className="text-primary text-xl" />
                <p className="mt-2 text-xs text-black ">
                  {p.snippet}
                </p>
              </div>
              <div className="border-t-[0.5px] border-gray-400 flex items-center mt-2 pt-3 justify-between">
                <div className="w-28 h-8 relative">
                  <Image src={`assets/icons/press/${p.logo}`} layout="fill" className="object-contain object-left" alt={p.logo} />
                </div>
                <div>
                  <FiExternalLink />
                </div>
              </div>
            </div>
          </a>
        </div>)}
      </div>
    </div>
  </div>
}

export default PressBlock
