import useSWR from "swr"
import useSWRInfinite from "swr/infinite"

export const fetcher = url => fetch(url).then(res => res.json())

export const useFetch = (path) => {
    const swr = useSWR(path, fetcher)
    return swr
}

export const useInfiniteFetch = (path, queryParams, options) => {
    if (!path) {
        throw new Error("Path is required")
    }

    const url = path
    const PAGE_LIMIT = options?.limit || 10

    const { data: d, error, size, setSize, mutate } = useSWRInfinite(
        index => `${url}?page=${index + 1}&limit=${PAGE_LIMIT}&${queryParams}`,
        fetcher,
        {
            refreshInterval: 0,
            revalidateFirstPage: false,
        }
    )

    const data = d ? [].concat(...d) : []
    const isLoadingInitialData = !d && !error
    const isLoadingMore =
        isLoadingInitialData ||
        (size > 0 && d && typeof d[size - 1] === "undefined")
    const isEmpty = d?.[0]?.length === 0
    const isReachingEnd =
        isEmpty || (d && d[d.length - 1]?.length < PAGE_LIMIT)

    return { data, error, isLoadingMore, size, setSize, isReachingEnd, mutate }
}
