'use client';

import { PressBlockDesktop } from 'components/index/PressBlock'
import Image from 'lib/image'
import Link from 'next/link'
import useSWR from 'swr'
import footerlinks from '../../content/footer.json'
import { useFetch } from 'lib/useInfiniteFetch';


const Desktopfooter = () => {
    const { data } = useFetch('/api/city/nearme?limit=100')

    return <>
        <div className="relative bg-gray-50 mt-12 pb-12">
            <PressBlockDesktop />
        </div>
        <div className="relative bg-gray-50" style={{ height: '80vh' }}>
            <div className="absolute w-full top-0 z-10">
                <div className="container">
                    <div className="flex items-center justify-between">
                        <p className="text-2xl text-gray-500">Recognised by</p>
                        <div className="w-56 grayscale hover:grayscale-0 transition-all cursor-pointer">
                            <Image src={'assets/icons/press/startupindia.png'} layout="responsive" width={155} height={34} />
                        </div>
                        <div className="w-56 grayscale hover:grayscale-0 transition-all cursor-pointer">
                            <Image src={'assets/icons/press/startupuk.png'} layout="responsive" width={200} height={87} />
                        </div>
                    </div>
                </div>
            </div>
            <Image layout="fill" objectFit="cover" objectPosition="center top" src={'assets/banners/footer.jpg'} alt="footer" />
            <div className="absolute w-full bottom-4">
            {data?.hits ? <div className="container mb-8">
                    <p className="font-semibold text-gray-400 mb-1">Choose from 500+ Stays in India</p>
                    {data.hits.map((hit, i) => <div key={i} className='inline-block'>
                        <Link href={`/city/${hit.uname}`} className="text-[0.75rem] text-white">
                            {hit.name}
                        </Link>
                        {i!==(data.hits.length-1)?<span className="text-[0.75rem] text-white">&nbsp;|&nbsp;</span>:null}
                    </div>)}
                </div> : null}
                <div className="container mx-auto flex space-x-4 justify-between items-start">
                    {footerlinks.map((link, k) => {
                        return (
                            <div key={k} className="flex flex-col space-y-5 h-full">
                                <p className="text-sm font-bold text-gray-400">{link.name}</p>
                                {link.links.map((l, i) => l.externalLink ?
                                    <a key={i} href={l.externalLink} target="_blank" rel="noopener noreferrer"
                                        className="text-xs md:text-sm text-white">{l.name}</a> : <Link key={i} href={l.link} className="text-xs md:text-sm text-white">
                                        {l.name}
                                    </Link>)}
                            </div>
                        );
                    })}
                </div>
                <div className="container mx-auto text-center mt-6 text-xs text-white">
                    © 2021 TrippNTale Outdoor Adventures Private Limited. All rights reserved.
                </div>
            </div>
        </div>
    </>;
}

export default Desktopfooter;
